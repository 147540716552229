
<template>
    <div>
        نسبت های مالی<br>
        مجمع<br>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th v-for="(item, index) in stock_financialratios_meeting.headers" :key="index" v-if="stock_financialratios_meeting.headers">
                    {{ item.date }} <br> سال مالی {{ item.sal_mali }} <br> {{ item.cycle}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in stock_financialratios_meeting.rows" :key="index" v-if="stock_financialratios_meeting.rows">
                    {{ index }}
                    <td>
                        {{ item[0].value}}
                    </td>
                    <td>
                        {{ item[1].value}}
                    </td>
                    <td>
                        {{ item[2].value}}
                    </td>
                </tr>
            </tbody>
        </table>
        --------------------------------------------<br>
        میاندوره‌ای<br>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th v-for="(item, index) in stock_financialratios_midterm.headers" :key="index" v-if="stock_financialratios_midterm.headers">
                    {{ item.date }} <br> سال مالی {{ item.sal_mali }} <br> {{ item.cycle}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in stock_financialratios_midterm.rows" :key="index" v-if="stock_financialratios_midterm.rows">
                    {{ index }}
                    <td>
                        {{ item[0].value}}
                    </td>
                    <td>
                        {{ item[1].value}}
                    </td>
                    <td>
                        {{ item[2].value}}
                    </td>
                </tr>
            </tbody>
        </table>
        --------------------------------------------<br>
        پیس بینی<br>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th v-for="(item, index) in stock_financialratios_forecast.headers" :key="index" v-if="stock_financialratios_forecast.headers">
                    {{ item.date }} <br> سال مالی {{ item.sal_mali }} <br> {{ item.cycle}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in stock_financialratios_forecast.rows" :key="index" v-if="stock_financialratios_forecast.rows">
                    {{ index }}
                    <td>
                        {{ item[0].value}}
                    </td>
                    <td>
                        {{ item[1].value}}
                    </td>
                    <td v-if="item[2]">
                        {{ item[2].value}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'StockFinancialratios',
    data() {
        return {
            // مجمع
            stock_financialratios_meeting : null,
            // میاندوره‌ای
            stock_financialratios_midterm : null,
            // پیش بینی
            stock_financialratios_forecast : null,
        }
    },
    mounted() {
        this.getProfileFinancialratios();
    },
    methods: {
        getProfileFinancialratios() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-financialratios/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_financialratios_meeting = api_response.data.response.financialratios.body.response.data[1];
                        this.stock_financialratios_midterm = api_response.data.response.financialratios.body.response.data[3];
                        this.stock_financialratios_forecast = api_response.data.response.financialratios.body.response.data[2];
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>